<template>
    <div>
        <!-- 头部 -->
        <Header :active=2 :full='fullWidth'/>
        <!-- 头部图片pc -->
        <el-image style="width: 100%; margin-top:74px" :src="url" v-if="fullWidth"></el-image>
        <!-- 头部图片h5 -->
        <el-image style="width: 100%; margin-top:52px" :src="url1" v-if="!fullWidth"></el-image>
        <!-- 产品介绍pc -->
        <div class="productIntroductionPC" v-if="fullWidth">
            <div class="title" style="width:50%;">
                <p >Product introduction</p>
                <span>产品介绍</span>
                <div></div>
            </div>
            <div class="productIntroduction-con" >
                <img src="../assets/innovative/background.png" alt="">
                <el-carousel  width="580px" class="con" style="padding:30px;position:absolute;left: 553px;top: 75px;" ref="carousel" :autoplay="false" :initial-index='initialIndex'>
                    <el-carousel-item v-for="item in introCarousel" :key="item.id" style="line-height:13px;">
                        <h5 style="font-size:16px;color:#474747;margin-top:10px;">{{item.name}}</h5>
                        <h6 style="font-size:12px;color:#9D9D9D;margin-top:10px;">{{item.littleName}}</h6>
                        <span style="font-size:14px;color:#474747;">{{item.con}}</span>
                    </el-carousel-item>
                    <el-carousel-item  style="line-height:13px;" class="con2">
                        <h5 style="font-size:16px;color:#474747;margin-top:10px;">产品分类</h5>
                        <h6 style="font-size:12px;color:#9D9D9D;margin-top:10px;">Product Classification</h6>
                        <ul>
                            <li>
                                <img src="../assets/innovative/world.png" alt="" style="width:100px;height:100px;">
                                <p>国际版</p>
                                <span>不限定客户端支持多语种</span>
                            </li>
                            <li>
                                <img src="../assets/innovative/weixin.png" alt="" style="width:120px;height:100px;">
                                <p>微信版</p>
                                <span>支持公众号、小程序</span>
                                <span>支持引流、红包营销</span>
                            </li>
                        </ul>
                    </el-carousel-item>
                </el-carousel>
                <div class="more" v-show="initialIndex == 0" @click="changeIndex0()">
                    <img src="../assets/innovative/jiantou.png" alt="">
                    <span>查看产品分类</span>
                </div>
                <div class="more" v-show="initialIndex == 1" @click="changeIndex1()">
                    <img src="../assets/innovative/jiantou.png" alt="">
                    <span>查看产品介绍</span>
                </div>
            </div>
        </div>
        <!-- 产品介绍h5 -->
        <div class="productIntroductionH5" v-if="!fullWidth">
            <div class="title" style="width:50%;">
                <p>Product introduction</p>
                <span>产品分类</span>
                <div></div>
            </div>
            <div class="productIntroduction-con" >
                <img src="../assets/innovative/background.png" alt="">
                <el-carousel height="192px" width="50%" class="con" style="padding:15px;position:absolute; top: 36px; left: 119px; " ref="carousel" :autoplay="false" :initial-index='initialIndex'>
                    <el-carousel-item v-for="item in introCarousel" :key="item.id" style="line-height:13px;">
                        <h5 style="font-size:7px;color:#474747;">{{item.name}}</h5>
                        <h6 style="font-size:4px;color:#9D9D9D;margin-top:5px;">{{item.littleName}}</h6>
                        <span style="font-size:6px;color:#474747;height:190px;">{{item.con}}</span>
                    </el-carousel-item>
                    <el-carousel-item  style="line-height:13px;" class="con2">
                        <h5 style="font-size:7px;color:#474747;">产品分类</h5>
                        <h6 style="font-size:4px;color:#9D9D9D;margin-top:5px;">Product Classification</h6>
                        <ul>
                            <li>
                                <img src="../assets/innovative/world.png" alt="" style="width:30px;height:30px;">
                                <p>国际版</p>
                                <span>不限定客户端支持多语种</span>
                            </li>
                            <li>
                                <img src="../assets/innovative/weixin.png" alt="" style="width:40px;height:30px;">
                                <p>微信版</p>
                                <span>支持公众号、小程序、引流、红包营销</span>
                            </li>
                        </ul>
                    </el-carousel-item>
                </el-carousel>
                <div class="more" v-show="initialIndex == 0" @click="changeIndex0()">
                    <img src="../assets/innovative/jiantou.png" alt="">
                    <span>查看产品分类</span>
                </div>
                <div class="more" v-show="initialIndex == 1" @click="changeIndex1()">
                    <img src="../assets/innovative/jiantou.png" alt="">
                    <span>查看产品介绍</span>
                </div>
            </div>
        </div>
        <!-- 产品优势pc -->
        <div class="productAdvantagePC" v-if="fullWidth">
            <div class="title" style="width:50%;">
                <p >Product advantage</p>
                <span>产品优势</span>
                <div></div>
            </div>
            <div class="productAdvantagePC-con">
                <ul style="border-right:1px dashed #E02222">
                    <li v-for="item in advantageList_left" :key="item.name">
                        <div class="spans">
                            <h5>{{item.name}}</h5>
                            <div v-for="i in item.spanList" :key="i.span">
                                <span>{{i.span}}</span>
                            </div>
                        </div>
                        <div class="imgs">
                            <img :src="item.url" alt="">
                        </div>
                    </li>
                </ul>
                <ul style="margin-top:123px;">
                    <li v-for="item in advantageList_right" :key="item.name">
                        <div class="imgs">
                            <img :src="item.url" alt="">
                        </div>
                        <div class="spans" style="margin-left:40px;">
                            <h5>{{item.name}}</h5>
                            <div v-for="i in item.spanList" :key="i.span">
                                <span>{{i.span}}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
         <!-- 产品优势h5 -->
        <div class="productAdvantageH5" v-if="!fullWidth">
            <div class="title" style="width:50%;">
                <p>Product advantage</p>
                <span>产品优势</span>
                <div></div>
            </div>
            <div class="productAdvantagePC-con">
                <ul style="border-right:1px dashed #E02222">
                    <li v-for="item in advantageList_left" :key="item.name">
                        <div class="spans">
                            <h5 style="margin-bottom: 5px">{{item.name}}</h5>
                            <div v-for="i in item.spanList" :key="i.span">
                                <span>{{i.span}}</span>
                            </div>
                        </div>
                        <div class="imgs" style="margin-left:15px;">
                            <img :src="item.url" alt="">
                        </div>
                    </li>
                </ul>
                <ul style="margin-top:70px;">
                    <li v-for="item in advantageList_right" :key="item.name">
                        <div class="imgs">
                            <img :src="item.url" alt="">
                        </div>
                        <div class="spans" style="margin-left:15px;">
                            <h5 style="margin-bottom: 5px">{{item.name}}</h5>
                            <div v-for="i in item.spanList" :key="i.span">
                                <span>{{i.span}}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 产品详情pc -->
        <div class="detailPC" v-if="fullWidth">
            <div class="title" style="width:50%;">
                <p>Product details</p>
                <span>产品详情</span>
                <div></div>
            </div>
            <img src="../assets/innovative/detail_pc.png" alt="">
        </div>
        <!-- 产品详情h5 -->
        <div class="detailH5" v-if="!fullWidth">
            <div class="title" style="width:50%;">
                <p>Product details</p>
                <span>产品详情</span>
                <div></div>
            </div>
            <img src="../assets/innovative/detail_h5.png" alt="">
        </div>
        <!-- 底部 -->
        <Footer :full='fullWidth'/>
    </div>
</template>

<script>
import  Header from "@/components/Header.vue";
import  Footer from "@/components/Footer.vue";
export default {
    data(){
        return{
            url:require('../assets/innovative/chuangxin.png'),
            url1:require('../assets/innovative/move.png'),
            initialIndex:0,

            fullWidth:true,//屏幕宽度
            // 产品介绍轮播内容
            introCarousel:[
                {
                    id:1,
                    name:'产品介绍',
                    littleName:'Product Introduction',
                    con:'火眼防伪追溯系统是国内技术领先的防伪追溯平台，也是国内少数可实现“不可复制/不可篡改”的顶级防伪追溯标准的平台之一。可实现基于一物一码和人工智能以及区块链技术对商品的生产、仓储、运输、销售及市场管理等等环节进行商品全生命周期的数字化-智能化跟踪管理，不仅通过人工智能和生物识别技术从制造源头杜绝伪造仿冒，更通过区块链技术做到商品流通全过程的可视化追溯。在保护消费者合法权益的同时，也充分保护和提升品牌价值。'
                },
            ],
            // 产品优势
            advantageList_left:[
                {
                    name:'1.成本低',
                    spanList:[
                        {span:'• 最大化利旧品牌商现有包装方案'},
                        {span:'• 不依赖特殊物料材质和印刷工艺'},
                        {span:'• 自主知识产权，自有实验室和工厂'}
                    ],
                    url:require('../assets/innovative/1.svg')
                },
                {
                    name:'3.高兼容性',
                    spanList:[
                        {span:'• 多语种多平台国际通用（普通浏览器/微信/支付宝及客户自定义客户端）'},
                        {span:'• 云端部署亦可根据客户需要私有化部署'},
                        {span:'• 兼容世界主流变码体制（QR/DM/PDF417/汉信等）'},
                        {span:'• 兼容所有印刷工艺和材质'}
                    ],
                    url:require('../assets/innovative/3.svg')
                },
                {
                    name:'5.高拓展性/可定制性',
                    spanList:[
                        {span:'• 通用接口开放（OPEN API）搭配不同平台使用为品牌商提供营销促销能力'},
                        {span:'• 除核心保密编码技术外所有系统界面和业务环节均可定制'},
                        {span:'• 可融合品牌商现有ERP/SRM/MES等系统'}
                    ],
                    url:require('../assets/innovative/5.svg')
                },
                {
                    name:'7.大数据能力',
                    spanList:[
                        {span:'• 风险分析和预警告警（地图标注仿冒品位置分布）'},
                        {span:'• 目标用户统计分析/画像能力'},
                        {span:'• 目标用户地理位置信息统计标注（LBS）'},
                        {span:'• 流通商品散布分析/渠道分析（防窜货等）'},
                        {span:'• 用户反馈分析'},
                        {span:'• 结合平台营销功能的电商信息统计分析'}
                    ],
                    url:require('../assets/innovative/7.svg')
                }
            ],
            advantageList_right:[
                {
                    name:'2.易使用',
                    spanList:[
                        {span:'• 不依赖专控设备鉴别，普通移动设备（如智能手机）即可'},
                        {span:'• 防混淆，极端场景物料污损30%仍可识别/画像能力'},
                        {span:'• 文字/语音/视频播报结果满足各种人群使用'},
                    ],
                    url:require('../assets/innovative/2.svg')
                },
                {
                    name:'4.安全可靠/不可复制',
                    spanList:[
                        {span:'• 不可逆编码技术/单件商品编码全球唯一（UUID）'},
                        {span:'• 金融级安全策略（CLASS-4）'},
                        {span:'• 不对称加密技术'},
                        {span:'• 随机特征提取和编码技术'},
                        {span:'• LBS及专控设备保护技术'},
                        {span:'• HTTPS通信协议和区块链鉴权体系'}
                    ],
                    url:require('../assets/innovative/4.svg')
                },
                {
                    name:'6.长期存储',
                    spanList:[
                        {span:'• 最低5年数据免费存储'},
                        {span:'• 特殊行业实现商品全生命周期存储（耐用品）'},
                        {span:'• 私有化部署可实现数据永久存储'},
                    ],
                    url:require('../assets/innovative/6.svg')
                }
            ]
        }
    },
    components: {
        Header,
        Footer
    },
    mounted(){
        window.onresize = () => {//监听屏幕变化
        this.page_width();
        this.setRem()
        };
        this.page_width();
        this.setRem()
    },
    methods:{
        // 改变产品介绍下标
        changeIndex0(){
            this.initialIndex = 1
            this.$refs.carousel.setActiveItem(1)
        },
        changeIndex1(){
            this.initialIndex = 0
            this.$refs.carousel.setActiveItem(2)
        },
        page_width() {//获取屏幕宽度
            let screenWidth = window.screen.width;
            console.log(screenWidth);
            if (screenWidth < 800) {
            this.fullWidth = false;
            } else {
            this.fullWidth = true;
            }
        },
        setRem() {
            var whdef = 100/1920;// 表示1920的设计图,使用100PX的默认值
            var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
            var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
            document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
        }
    },
}
</script>

<style lang="less" scoped>
// 标题
.title{
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;
  p{
    font-size:18px ;
    color: #E02222;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
  span{
    display: block;
    font-size: 16px;
  }
  div{
    background-color: #DDDDDD;
    width: 64px;
    height: 5px;
    border-radius: 50px;
    margin-top: 10px;
  }
}
// 产品介绍PC端

.productIntroductionPC{
    width: 80%;
    margin: 0 auto;
    background: #fff;
    padding: 30px 0 100px 0;
    /deep/.el-carousel__indicators--horizontal{
        left: 70%;
        bottom: 22px;
    }
    /deep/.el-carousel__indicator--horizontal .el-carousel__button{
        background: #D8D8D8 !important;
    }
    /deep/.el-carousel__indicator--horizontal .el-carousel__button{
        background: #E02222 !important;
    }
    .productIntroduction-con{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        img{

            width: 50%;
            height: 510px;
            display: inline-block;
        }
        .con{
            width: 50%;
            display: inline-block;
            height: 451px;
            background: #fff;
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.11);
            border-radius: 17px;
        }
        .con2{
            ul{
                list-style: none;
                display: flex;
                justify-content: space-around;
                margin-top: 75px;;
                li{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    line-height: 20px;
                    p{
                        margin-top: 10px;
                        font-size: 14px;
                        color: #000;
                        font-weight: bold;
                    }
                    span{
                        display: block;

                        font-size: 12px;
                        color: #252525;
                    }
                }
            }
        }
        .more{
            position: absolute;
            bottom: 17px;
            z-index: 5;
            left: 589px;
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                width: 32px;
                height: 32px;
                border-radius: 6px;
            }
            span{
                color: #9D9D9D;
                font-size: 12px;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
}
// 产品介绍h5

.productIntroductionH5{
    background: #fff;
    padding: 30px 0 100px 0;
    /deep/.el-carousel__arrow--left{
        display: none  !important;
    }
    /deep/.el-carousel__arrow--right{
        display: none  !important;
    }
    /deep/.el-carousel__indicators--horizontal{
        left: 66%;
        bottom: 7px;
    }
    /deep/.el-carousel__indicator--horizontal .el-carousel__button{
        background: #D8D8D8 !important;
    }
    /deep/.el-carousel__indicator--horizontal .el-carousel__button{
        background: #E02222 !important;
        width: 20px;
    }
    .productIntroduction-con{
        display: flex;
        // width: 90%;
        margin: 0 auto;
        position: relative;
        margin-left: 5%;
        img{
            // width: 50%;
            height: 240px;
            display: inline-block;
        }
        .con{
            width: 63%;
            display: inline-block;
            height: 240px;
            background: #fff;
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.11);
            border-radius: 17px;
        }
        .con2{
            ul{
                list-style: none;
                display: flex;
                justify-content: space-around;
                margin-top: 20px;;
                li{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    line-height: 15px;
                    p{
                        margin-top: 10px;
                        font-size: 10px;
                        color: #000;
                        font-weight: bold;
                    }
                    span{
                        display: block;
                        width: 85px;
                        height: 50px;
                        font-size: 8px;
                        color: #252525;
                    }
                }
            }
        }
        .more{
            position: absolute;
            bottom: -24px;
            z-index: 5;
            left: 135px;
            display: flex;
            align-items: center;
            img{
                width: 13px;
                height: 13px;
                border-radius: 2px;
            }
            span{
                color: #9D9D9D;
                font-size: 5px;
                display: inline-block;
                margin-left: 5px;
            }
        }
    }
}
// 产品优势pc
.productAdvantagePC{
    background: #F8F8F8;
    padding-bottom: 100px;
    padding-top: 20px;
    .productAdvantagePC-con{
        font-size: 30px;
        display: flex;
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        ul{
            // width: 32%;
            list-style: none;
            li:nth-of-type(1){
                margin-top:0px;
            }
            li{
                width:392px;
                height: 200px;
                display: flex;
                background: #FFF;
                padding: 30px;
                align-items: center;
                margin-top: 120px;
                .imgs{
                    width:10%;
                    img{
                    }
                }
                .spans{
                    width: 90%;
                    line-height: 14px;
                    h5{
                        font-size: 14px;
                    }
                    div{
                        span{
                            font-size: 12px;
                            color: #252525;
                        }
                    }
                }
            }
        }
    }
}
// 产品优势h5
.productAdvantageH5{
    background: #F8F8F8;
    padding: 20px 0;
    .productAdvantagePC-con{
        display: flex;
        width: 95%;
        margin: 0 auto;
        justify-content: center;
        ul{
            width: 50%;
            list-style: none;
            li:nth-of-type(1){
                margin-top:0px;
            }
            li{
                display: flex;
                background: #FFF;
                padding: 15px;
                align-items: center;
                margin-top: 100px;
                .imgs{
                    width:10%;
                    img{
                        width: 22px;
                        height: 22px;
                    }
                }
                .spans{
                    width: 90%;
                    line-height: 9px;
                    h5{
                        font-size: 8px;
                    }
                    div{
                        span{
                            font-size: 6px;
                            color: #252525;
                        }
                    }
                }
            }
        }
    }
}
// 产品详情pc
.detailPC{
    width: 64%;
    padding: 30px 0;
    margin: 0 auto;
    background: #fff;
    text-align: center;
    img{
        width:100%;
        height: auto;
    }
}
// 产品详情h5
.detailH5{
    width: 95%;
    margin:0 auto;
    padding: 30px 0;
    background: #fff;
    img{
        width:100%;
        height: 100%;
    }
}
</style>
